@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik+Microbe&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}
body {
  background-color: black;
}

.App {
  max-width: 100vw;
  overflow-x: hidden;
  padding-bottom: 3em;
}

.Toastify__toast {
  width: 90vw;
  z-index: 999;
  padding: 5px !important;
  font-size: 12px;
  bottom: 6em;
  margin: 0 auto;
  min-height: 10px !important;
  transition: 2s all ease-in-out;
  border-radius: 6px;
  color: white !important;
  background-color: black;
}

.Toastify__toast button {
  display: none;
}

.Toastify__toast-icon {
  width: 15px;
  height: 15px;
}

#musicLottieLoader {
  color: '#ffff';
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
